import React from "react";
import Title from "../../../sharedComponents/title";
import Skill from "./skill";

import "./Skills.scss";

const Skills = () => {
  return (
    <div className="Skills row center-xs" id="skills-section">
      <Title>Skills</Title>
      <Skill skills={feSkills} category="Front-End" />
      <Skill skills={beSkills} category="Back-End" />
      <Skill skills={otherSkills} category="CI/CD and other" />
    </div>
  );
};

export default Skills;

const feSkills = [
  {
    name: "React",
    experience: "6+ years",
  },
  {
    name: "TypeScript",
    experience: "5+ years",
  },
  {
    name: "Redux",
    experience: "6+ years",
  },
  {
    name: "JavaScript/ES6",
    experience: "5+ years",
  },
  {
    name: "HTML5",
    experience: "7+ years",
  },
  {
    name: "CSS3",
    experience: "7+ years",
  },
  {
    name: "Styled components",
    experience: "5+ years",
  },
  {
    name: "Jest/Enzyme/RTL",
    experience: "6+ years",
  },
];

const beSkills = [
  {
    name: "Node.js",
    experience: "4+ years",
  },
  {
    name: "C#/.NET",
    experience: "2+ years",
  },
  {
    name: "Java",
    experience: "1 year",
  },
  {
    name: "SQL Server",
    experience: "2+ years",
  },
  {
    name: "MongoDB",
    experience: "1+ years",
  },
  {
    name: "Express.js",
    experience: "1+ years",
  },
];

const otherSkills = [
  {
    name: "Amazon Web Services",
    experience: "2+ year",
  },
  {
    name: "Microsoft Azure",
    experience: "5+ years",
  },
  {
    name: "Docker",
    experience: "1+ years",
  },
  {
    name: "Jenkins",
    experience: "3+ year",
  },
  {
    name: "Git",
    experience: "7+ years",
  },
];
