import React from "react";
import Title from "../../../sharedComponents/title";
import Subtitle from "../../../sharedComponents/subtitle";
import SecondaryTitle from "../../../sharedComponents/secondary-title";
import Experience from "./experience";
import reduxLogo from "../../../assets/logos/redux.png";
import webpackLogo from "../../../assets/logos/webpack.svg";
import sassLogo from "../../../assets/logos/sass.png";
import enzymeLogo from "../../../assets/logos/enzyme.png";
import jestLogo from "../../../assets/logos/jest-svg.svg";
import pythonLogo from "../../../assets/logos/python.svg";
import nodeLogo from "../../../assets/logos/nodejs.png";
import tsLogo from "../../../assets/logos/ts.svg";

import "./Profile.scss";

const Profile = () => {
  const skillsOne = (
    <>
      <span className="icon-container">
        <i className="fab fa-react" />
        <span className="tech-title">React</span>
      </span>
      <span className="icon-container">
        <img src={reduxLogo} className="redux-icon" alt="Redux" />
        <span className="tech-title">Redux</span>
      </span>
      <span className="icon-container">
        <img src={tsLogo} className="redux-icon" alt="TypeScript" />
        <span className="tech-title">TypeScript</span>
      </span>
      <span className="icon-container">
        <img src={sassLogo} className="redux-icon" alt="SASS" />
        <span className="tech-title">SASS</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-js" />
        <span className="tech-title">ES6</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-html5" />
        <span className="tech-title">HTML5</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-css3-alt" />
        <span className="tech-title">CSS3</span>
      </span>
      <span className="icon-container">
        <img src={webpackLogo} className="webpack-icon" alt="Webpack" />
        <span className="tech-title">Webpack</span>
      </span>
      <span className="icon-container">
        <img src={jestLogo} className="redux-icon" alt="Jest" />
        <span className="tech-title">Jest</span>
      </span>
      <span className="icon-container">
        <img src={enzymeLogo} className="redux-icon" alt="Enzyme" />
        <span className="tech-title">Enzyme</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-git-alt" />
        <span className="tech-title">Git</span>
      </span>
    </>
  );

  const skillsFive = (
    <>
      <span className="icon-container">
        <i className="fab fa-react" />
        <span className="tech-title">React</span>
      </span>
      <span className="icon-container">
        <img src={tsLogo} className="redux-icon" alt="TypeScript" />
        <span className="tech-title">TypeScript</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-html5" />
        <span className="tech-title">HTML5</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-css3-alt" />
        <span className="tech-title">CSS3</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-js" />
        <span className="tech-title">JavaScript</span>
      </span>
      <span className="icon-container">
        <img src={webpackLogo} className="webpack-icon" alt="Webpack" />
        <span className="tech-title">Webpack</span>
      </span>
      <span className="icon-container">
        <img src={jestLogo} className="redux-icon" alt="Jest" />
        <span className="tech-title">Jest</span>
      </span>
      <span className="icon-container">
        <img src={enzymeLogo} className="redux-icon" alt="Enzyme" />
        <span className="tech-title">RTL</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-aws" />
        <span className="tech-title">Amazon</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-github" />
        <span className="tech-title">Github</span>
      </span>
    </>
  );

  const skillsSix = (
    <>
      <span className="icon-container">
        <i className="fab fa-react" />
        <span className="tech-title">React</span>
      </span>
      <span className="icon-container">
        <img src={tsLogo} className="redux-icon" alt="TypeScript" />
        <span className="tech-title">TypeScript</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-html5" />
        <span className="tech-title">HTML5</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-css3-alt" />
        <span className="tech-title">CSS3</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-js" />
        <span className="tech-title">JavaScript</span>
      </span>
      <span className="icon-container">
        <img src={nodeLogo} className="redux-icon" alt="Nodejs" />
        <span className="tech-title">Node.js</span>
      </span>
      <span className="icon-container">
        <img src={jestLogo} className="redux-icon" alt="Jest" />
        <span className="tech-title">Jest</span>
      </span>
      <span className="icon-container">
        <img src={enzymeLogo} className="redux-icon" alt="Enzyme" />
        <span className="tech-title">RTL</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-github" />
        <span className="tech-title">Github</span>
      </span>
    </>
  );

  const skillsTwo = (
    <>
      <span className="icon-container">
        <i className="fab fa-react" />
        <span className="tech-title">React</span>
      </span>
      <span className="icon-container">
        <img src={reduxLogo} className="redux-icon" alt="Redux" />
        <span className="tech-title">Redux</span>
      </span>
      <span className="icon-container">
        <img src={sassLogo} className="redux-icon" alt="SASS" />
        <span className="tech-title">SASS</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-js" />
        <span className="tech-title">ES6</span>
      </span>
      <span className="icon-container">
        <img src={nodeLogo} className="redux-icon" alt="Nodejs" />
        <span className="tech-title">Node.js</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-html5" />
        <span className="tech-title">HTML5</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-css3-alt" />
        <span className="tech-title">CSS3</span>
      </span>
      <span className="icon-container">
        <img src={webpackLogo} className="webpack-icon" alt="Webpack" />
        <span className="tech-title">Webpack</span>
      </span>
      <span className="icon-container">
        <img src={jestLogo} className="redux-icon" alt="Jest" />
        <span className="tech-title">Jest</span>
      </span>
      <span className="icon-container">
        <img src={enzymeLogo} className="redux-icon" alt="Enzyme" />
        <span className="tech-title">RTL</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-git-alt" />
        <span className="tech-title">Git</span>
      </span>
    </>
  );

  const skillsFour = (
    <>
      <span className="icon-container">
        <i className="fab fa-js" />
        <span className="tech-title">JavaScript</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-html5" />
        <span className="tech-title">HTML5</span>
      </span>
      <span className="icon-container">
        <i className="fab fa-css3-alt" />
        <span className="tech-title">CSS3</span>
      </span>
      <span className="icon-container">
        <img src={pythonLogo} className="redux-icon" alt="Python" />
        <span className="tech-title">Python</span>
      </span>
      <span className="icon-container">
        <i className="fas fa-database" />
        <span className="tech-title">Cassandra</span>
      </span>
    </>
  );

  return (
    <div className="Profile row center-xs" id="experience-section">
      <Title>Work Experience</Title>
      <Subtitle>
        "I am a great believer in luck, and I find the harder I work the more I
        have of it." - Stephen Leacock
      </Subtitle>
      <SecondaryTitle>Career</SecondaryTitle>
      <Experience
        title={"Senior Front End Engineer"}
        companyName={"Bupa"}
        period={"January 2023 - Present"}
        location={"Melbourne, Australia"}
        companyLink={"https://bupa.com.au"}
        skills={skillsSix}
      >
        • Led the creation and maintenance of a reusable component library for Bupa, resulting in a more 
        consistent user experience and efficient development process across multiple products.
        <br />
        • Built the reusable component library using React, Next.js, TypeScript, Roll up, HTML5, MUI, Styled 
        components
        <br />
        • Collaborated with designers and developers to ensure every component met design standards,
        technical requirements, and accessibility requirements.
        <br />
        • Regularly presented the component library and its benefits to stakeholders, including project 
        managers and executives, resulting in increased buy-in and adoption across the organization.
        <br />
        • Worked with DevOps Engineers to build out and simplify various CI/CD pipelines using Azure including the process of releasing the component library.
        <br />
        • Established coding standards and best practices for the shared library, ensuring consistency and maintainability across all components.
        <br />
        • Wrote unit tests to improve the reliability of the code base, using Jest and React Testing Library (TDD)
        <br />
        • Interviewed and helped hire Front End Engineers for Bupa.
        <br />
        • Mentored and code reviewed other developers' code to ensure code quality and standards were met.
      </Experience>
      <Experience
        title={"Senior Software Engineer (Front End)"}
        companyName={"Open Universities Australia"}
        period={"April 2021 - January 2023 (1 year 10 months)"}
        location={"Melbourne, Australia"}
        companyLink={"https://open.edu.au"}
        skills={skillsFive}
      >
        • Led discovery & development for building various customer-facing web
        journeys on OUA’s website using React, Next.js, TypeScript, Webpack 4,
        HTML5, Styled components
        <br />
        • Built out OUA’s reusable React component library which heavily reduced
        code duplication
        <br />
        • Conducting numerous A/B tests using Optimizely on different areas of
        the site to improve customer interaction and drive leads and enrolments.
        <br />
        • Worked with DevOps Engineers to build out various CI/CD pipelines
        using AWS, Jenkins, & GitHub
        <br />
        • Utilised DataDog (an application monitoring and logging platform) to
        enable full visibility of the application stake for logging, tracing and
        metrics.
        <br />
        • Personalised multiple user experiences by using Tealium Audiences.
        <br />
        • Wrote and refactored unit tests to improve the reliability of the code
        base, using Jest, and RTL
        <br />• Mentored Junior, Mid-Level Engineers / Pair programming
        <br />• Conducted technical interviews for Engineering roles at OUA
        <br />• Defined and implemented best practices and standards for OUA to
        deliver high-quality code / code reviews
        <br />• Worked in the Sitecore CMS to seamlessly integrate complex React
        applications using C# & .NET
        <br />• Worked closely with UI/UX designers to ensure design &
        accessibility requirements are translated correctly
      </Experience>
      <Experience
        title={"Front End Developer"}
        companyName={"Spark New Zealand"}
        period={"February 2019 - March 2021 (2 years)"}
        location={"Auckland, New Zealand"}
        companyLink={"https://spark.co.nz"}
        skills={skillsOne}
      >
        • Built various customer facing web journeys from scratch including
        Broadband, Billing and Shop journeys.
        <br />
        • Built out Spark’s reusable React component library which heavily
        reduced code duplication
        <br />
        • Wrote and refactored unit tests to improve the reliability of the code
        base, using Jest and Enzyme
        <br />
        • Defined and implemented best practices and coding standards for the
        organization to deliver high quality code (reducing technical debt)
        <br />
        • Worked closely with UI/UX designers to ensure design is translated
        correctly
        <br />
        • Worked with AEM developers to seamlessly integrate React.js and add
        content authoring across journey’s
        <br />
        • Mentored junior and graduate developers / Pair programming
        <br />• Managed a small team of Front-End Developers & lead agile
        ceremonies including, backlog grooming, planning and retro.
      </Experience>

      <Experience
        title={"Front End Developer"}
        companyName={"Genesis Energy"}
        period={"March 2017 – February 2019 (2 years)"}
        location={"Auckland, New Zealand"}
        companyLink={"https://genesisenergy.co.nz"}
        skills={skillsTwo}
      >
        • Developed & maintained the Energy Management and Customer Self-Service
        web app from scratch that is used by over 100,000 customers
        <br />
        • Code reviewed other developers code to ensure code quality and
        standards are met
        <br />
        • Created/maintained test scripts with Jest and Enzyme reducing future
        defects and bugs
        <br />
        • Worked closely with UI/UX designers to ensure design is translated
        correctly
        <br />
        • Worked with Node.js/Express.js/MongoDB backend with Docker
        microservices to support the Front End
        <br />
        • Contributed to development of APIs, including, electricity/LPG/natural
        gas usage data and user data
        <br />• Tracked and managed 2 weekly sprints with JIRA
      </Experience>

      <Experience
        title={"Microsoft Intern"}
        companyName={"Genesis Energy"}
        period={"December 2016 – February 2017 (3 months)"}
        location={"Auckland, New Zealand"}
        companyLink={"https://genesisenergy.co.nz"}
        skills={
          "HTML5, CSS/SCSS, C#, Visual Studio, JavaScript, VSTS, SQL, Azure"
        }
      >
        • Developed an energy monitoring and management dashboard prototype,
        using HTML5, CSS3, JavaScript, C#, Visual Studio and Bootstrap
        <br />
        • Developed an LPG monitoring prototype using Visual Studio in 3 days,
        saving the company $25K
        <br />
        • Integrated .NET C# JSON APIs with the Front End
        <br />
        • Continuously deployed web projects using Microsoft Azure
        <br />
        • Wrote web automation test scripts in C# and conducted cross browser
        testing
        <br />• Extracted information from SQL database, to build managerial
        reports on key user metrics
      </Experience>

      <Experience
        title={"Test Analyst Intern"}
        companyName={"Foster Moore"}
        period={"November 2015 – February 2016 (4 months)"}
        location={"Auckland, New Zealand"}
        companyLink={"https://fostermoore.co.nz"}
        skills={"Selenium, RobotFramework, Python, Jenkins"}
      >
        • Converted legacy web automation test scripts using Robot Framework,
        Selenium & Python
        <br />
        • Troubleshooted and optimised automation web tests and CI/CD pipelines
        <br />
        • Continuously deployed test scripts with Jenkins
        <br />• Customised Jenkins to run and view test results of the latest
        deployment automatically
      </Experience>

      <Experience
        title={"Software Engineer Intern"}
        companyName={"Orion Health"}
        period={"November 2014 – February 2015 (4 months)"}
        location={"Auckland, New Zealand"}
        companyLink={"https://orionhealth.com"}
        skills={skillsFour}
      >
        • Converted legacy web automation test scripts using Robot Framework,
        Selenium & Python
        <br />
        • Troubleshooted and optimised automation web tests and CI/CD pipelines
        <br />
        • Continuously deployed test scripts with Jenkins
        <br />• Customised Jenkins to run and view test results of the latest
        deployment automatically
      </Experience>

      <SecondaryTitle>Education</SecondaryTitle>
      <Experience
        title={"BSc Computer Science & Information Systems"}
        companyName={"University of Auckland"}
        period={"2014 – 2017"}
        location={"Auckland, New Zealand"}
        companyLink={"https://auckland.ac.nz"}
        education
      ></Experience>
    </div>
  );
};

export default Profile;
